export const environment = {
    production: true,
    banorte: false,
    ente: "H. Ayuntamiento de Juan José Rios",
    administracion: "2024 - 2027",
    url_escudo: "../../../assets/jjrios/escudo_jjrios.png",//---
    url_administracion: "../../../assets/jjrios/escudo_jjrios.png",//---
    url_footer: "../../../assets/jjrios/escudo_jjrios.png",//---
    direcciones: [
        "Domicilio conocido S/N, Col. Centro",
        "C.P. 81110",
        "Juan José Ríos, Sinaloa., México"//---
    ],
    telefonos: [
        "668 812 0001"
    ],
    emails: [
        "ingresos@juanjoserios.gob.mx"
    ],
    url_aviso: "https://juanjoserios.gob.mx/",
    url_webapi: "https://servidorprogramacion.ddns.net/PAGOS_SADMUN_JUAN_JOSE_RIOS/api/",
    url_sitio: "https://pagosjuanjoserios.com/",
    url_rpt: 'http://servidorsecomrpt.ddns.net:8081/api/',
    cve_municipio: "027",
    municipio: "Juan José Rios",
    firebase: {
        apiKey: "AIzaSyB7Vo3x5QQiYWBdUPmNKw7QDbpsjwaBQFY",
        authDomain: "pagosjuanjoserios.firebaseapp.com",
        projectId: "pagosjuanjoserios",
        storageBucket: "pagosjuanjoserios.appspot.com",
        messagingSenderId: "391791391906",
        appId: "1:391791391906:web:6a40d014368e6db98893fb",
        measurementId: "G-9L8217659D"
    },
    leyenda_fundamento: "Ley de ingresos del municipio de Juan José Rios para el año 2017. Artículos 1,2,5,7,8,9. Ley de" +
        "Hacienda Municipal del Estado de Sinaloa vigente para el año 2017 Artículos 30 al 37 y 105" +
        "Capítulo II. Ley deCatastro del Estado de Sinaloa Artículos 13,14 23 al 26 y 32 al 34. Ley" +
        "de Gobierno Municipal del Estado de Sinaloa Artículo 59 Fracción III y IV.",
    leyenda_recibo: "EN CASO DE REQUERIR FACTURA ELECTRONICA, SOLICITARLO EN UN PLAZO NO MAYOR A 3 DIAS DESPUES DEL MES EN QUE REALIZO EL PAGO",
    id_express: "",
    llave: "",
    img_ayuda_1: '../assets/jjrios/pasos.png',
    img_ayuda_2: '../assets/jjrios/ubicarclavecatastral.png',
    img_ayuda_3: '../assets/jjrios/consultaadeudo.png'
};
